<template>
  <!-- Start Containerbar -->
  <div id="containerbar" data-app>
    <!-- Start Rightbar -->
    <div class="rightbar">
      <!-- Start Breadcrumbbar -->
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12">
            <h4 class="page-title">{{ $t("remission.remission") }}</h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/dashboard">{{ $t("remission.home") }}</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#"> {{ $t("remission.requisition.remission") }} </a>
                </li>
                <li @click="changeAction(1)" class="breadcrumb-item">
                  <a href="#">{{ $t(title) }}</a>
                </li>
                <li v-if="titleAction != ''" class="breadcrumb-item">
                  <a href="#">{{ $t(titleAction) }}</a>
                </li>
              </ol>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12 content-button">
            <button
              class="btn btn-outline-success ml-4"
              @click="changeAction(2)"
              v-if="
                withPermissionName(permissions.REM_SUPP_ADD_01) && showAction(1)
              "
            >
              <i class="feather icon-plus mr-1"></i>
              {{ $t("remission.remission_create") }}
            </button>
            <button
              v-if="!showAction(1)"
              @click="changeAction(1)"
              class="btn btn-outline-success"
            >
              <i class="ti-arrow-left mr-2"></i> {{ $t("global.return_to") }}
            </button>
          </div>
        </div>
      </div>

      <div class="contentbar">
        <div class="row">
          <div class="col-lg-12 col-xl-12">
            <div class="card">
              <AlertMessageComponent ref="alertRemmision" />
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-md-12">
                    <h5 v-if="titleAction != ''" class="card-title">
                      <i class="feather icon-file mr-2"></i
                      >{{ $t(titleAction) }}
                      <v-chip
                        class="ma-2"
                        v-if="modelRemission.idRemmision"
                        color="#263a5b"
                      >
                        {{
                          modelRemission.idRemmision +
                            " - " +
                            modelRemission.remissionkeyOld
                        }}
                      </v-chip>
                    </h5>
                    <h5 v-else class="card-title">
                      <i class="feather icon-file mr-2"></i>{{ $t(title) }}
                    </h5>
                  </div>
                </div>
              </div>
              <RemissionTableComponent
                v-if="showTable"
                v-show="showAction(1)"
                :headers="headers"
                :modelTable="listRemission"
                :pagesAtributes="pagesAtributes"
                :methodPage="listAllRemmisions"
                :remissionType="remissionType"
                :isButton="true"
                @excelTable="excelTable"
                @downloadPDF="downloadPDF"
                @detail="detail"
                :changeAction="changeAction"
                :isPDF="withPermissionName(permissions.REM_SUPP_DL_PDF_05)"
                :isDetail="withPermissionName(permissions.REM_SUPP_DETA_02)"
                :isUpdate="withPermissionName(permissions.REM_SUPP_EDIT_03)"
                :isAddProduct="
                  withPermissionName(permissions.REM_SUPP_ADD_PROD_REM_07)
                "
                :isUpdateProduct="
                  withPermissionName(permissions.REM_SUPP_EDIT_PROD_REM_08)
                "
                :isChangeRemission="false"
                :isFilter="true"
              />

              <div v-if="showAction(2)">
                <div class="col-12 pb-0">
                  <h6>
                    <v-icon class="pb-1"> mdi-numeric-1-box-outline</v-icon>
                    {{ $t("remission.title_info") }}
                  </h6>
                </div>
                <RemissionFormComponent
                  v-bind:modelRemission="modelRemission"
                  :loading="loading"
                  ref="remissionForm"
                />
                <div class="col-12 pb-0">
                  <h6>
                    <v-icon class="pb-1"> mdi-numeric-2-box-outline</v-icon>
                    {{ $t("remission.title_list") }}
                  </h6>
                </div>
                <RemissionTableProductComponent
                  :dataList="productTableList"
                  :headers="productsHeader"
                  :deleteItemRemmi="true"
                  :permission="false"
                  v-on:deleteRecordRemmi="deleteRecordRemmi"
                  v-bind:modelChange="modelChange"
                />
                <RemissionAddProductFormComponent
                  :productTableList="productTableList"
                  :modelProduct="modelProduct"
                  :validation="true"
                  :alert="alert"
                  :addProductListComponent="addProductListComponent"
                  ref="remissionAddProductFormComponent"
                />
                <ButtonAction
                  :onClick="onClick"
                  :buttonTitle="buttonTitle"
                  :validator="false"
                  :sending="false"
                />
              </div>
              <div v-if="showAction(5)">
                <div class="col-12 pb-0">
                  <h6>
                    <v-icon class="pb-1"> mdi-numeric-1-box-outline</v-icon>
                    {{ $t("remission.title_info") }}
                  </h6>
                </div>
                <RemissionFormComponent
                  v-bind:modelRemission="modelRemission"
                  :loading="loading"
                  ref="remissionForm"
                />
                <ButtonAction
                  :onClick="onClick"
                  :buttonTitle="buttonTitle"
                  :validator="false"
                  :sending="false"
                />
              </div>
              <div v-if="showAction(3)">
                <div class="col-12 pb-0">
                  <h6>
                    <v-icon class="pb-1">mdi-numeric-1-box-outline</v-icon>
                    {{ $t("remission.title_list") }}
                  </h6>
                </div>
                <RemissionTableProductComponent
                  :dataList="productTableList"
                  :headers="productsHeaderUpdate"
                  v-bind:modelChange="modelChange"
                />
                <div class="col-12 pt-5 pb-0">
                  <h6>
                    <v-icon class="pb-1">mdi-numeric-2-box-outline</v-icon>
                    Información del producto
                  </h6>
                </div>
                <RemissionAddProductFormComponent
                  v-bind:modelProduct="modelProduct"
                  :productTableList="productTableList"
                  :alert="alert"
                  :addProductListComponent="addProductUpdate"
                  ref="remissionAddProductFormComponent"
                />
              </div>
              <div v-if="showAction(4)">
                <div class="col-12 pb-0">
                  <h6>
                    <v-icon class="pb-1">mdi-numeric-1-box-outline</v-icon>
                    {{ $t("remission.title_list") }}
                  </h6>
                </div>
                <RemissionTableProductComponent
                  :dataList="productTableList"
                  :headers="productsHeaderUpdate"
                  :editProduct="true"
                  v-on:openModalEdit="openModalUpdateProduct"
                  v-bind:modelChange="modelChange"
                />
                <div class="pb-15"></div>
                <RemissionModalUpdateProductComponent
                  ref="RemissionModalUpdateProductComponent"
                  :modelProduct="modelProduct"
                  :dialogM="dialogM"
                  :modelRemission="modelRemission"
                  :currentItem="currentItem"
                  :alert="alert"
                  :originId="branchOffice"
                  :getProductRemission="reloadProductUpdate"
                />
              </div>
              <div v-if="showAction(6)">
                <div class="col-12 pb-0">
                  <h6>
                    <v-icon class="pb-1">mdi-numeric-1-box-outline</v-icon>
                    {{ $t("remission.title_list") }}
                  </h6>
                </div>
                <div class="card-body">
                  <div class="row p-0">
                    <div class="col-12 text-right">
                      <button
                        @click="loadRemmisionByBranchOffice()"
                        :disabled="modelChange.remmisionViewList.length < 1"
                        class="btn btn-outline-success"
                      >
                        <i class="feather icon-refresh-ccw mr-2"></i>
                        {{ $t("global.change_remission") }}
                      </button>
                    </div>
                  </div>
                </div>
                <RemissionTableProductComponent
                  :dataList="productTableList"
                  :headers="productsHeaderUpdate"
                  v-on:openModalEdit="openModalUpdateProduct"
                  :changeRemission="true"
                  v-bind:modelChange="modelChange"
                />
                <RemissionModalChangeProductComponent
                  v-bind:modelChange="modelChange"
                  :remmisionChangeList="remmisionChangeList"
                  v-bind:dialogM="dialogM"
                  :originId="branchOffice"
                  :alert="alert"
                  :reloadProductUpdate="reloadProductUpdate"
                />
                <div class="pb-12"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <RemissionsModalDetail
      v-if="showAction(1)"
      :model="model"
      v-bind:dialogM="dialogM"
      :remmisionViewHeaders="remmisionViewHeaders"
    />
  </div>
</template>

<script>
import { UtilFront, PermissionConstants } from "@/core";
import { ExcelRequest } from "@/core/request";
import { RemissionRequest } from "@/core/request";

/* Componetes de Remisión NEW */
import RemissionAddProductFormComponent from "@/components/remission/RemissionAddProductFormComponent";
import RemissionFormComponent from "@/components/remission/RemissionFormComponent";
import RemissionModalUpdateProductComponent from "@/components/remission/RemissionModalUpdateProductComponent";
import RemissionTableProductComponent from "@/components/remission/RemissionTableProductComponent";
import { SwalConfirm } from "@/core/SwalAlert";
import ButtonAction from "@/common/button/ButtonAction";

/* Componetes de Remisión */
import RemissionsModalDetail from "@/components/remission/RemissionsModalDetail";
import RemissionTableComponent from "@/components/remission/RemissionTableComponent";
import RemissionModalChangeProductComponent from "@/components/remission/RemissionModalChangeProductComponent";
import {
  REMMISION,
  CREATE_REMMISION,
  REMMISION_VIEW,
  CREATE_REMMISION_UPDATE,
} from "@/core/DataTableHeadersLocale";
import moment from "moment";
import ButtonPrintComponent from "@/common/table/ButtonPrintComponent";
import SelectBranchOffice from "@/common/select/SelectBranchOffice";
import AlertMessageComponent from "@/common/message/AlertMessageComponent";
/* VUEX */
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  data() {
    return {
      title: "remission.remission",
      remmisionHeaders: REMMISION(),
      remmisionViewHeaders: REMMISION_VIEW(),
      productsHeader: CREATE_REMMISION(),
      productsHeaderUpdate: CREATE_REMMISION_UPDATE(),
      headers: {},
      /* New variables */
      modelRemission: {
        idRemmision: "",
        suppliers: "",
        invoice: "",
        remissionkey: "",
        requisitionGobId: "",
        remissionType: "",
        observations: "",
        remissionkeyOld: "",
      },
      modelChange: {
        remmisionViewList: [],
      },
      modelProduct: {},
      productTableList: [],
      produdctsRemmision: [],
      currentItem: {},
      remmisionChangeList: [],
      action: 1,
      remissionType: 1,
      dialogM: {
        dialog: false,
        dialogUpdate: false,
        dialogRemmisionChange: false,
      },
      model: {
        userBranchOfficeDTO: {
          user: {
            name: "",
          },
        },
      },
      loading: false,
      permissions: PermissionConstants,
      /* Pagination */
      pagesAtributes: {
        currentPage: 0,
        itemsPerPage: 15,
        search: "",
        sortBy: "",
        sortFilters: {}
      },
      listRemission: {
        content: [],
        pageable: {},
        totalPages: 0,
        totalElements: 0,
      },
      showTable: true,
    };
  },
  computed: {
    ...mapState("auth", ["branchOffice", "actionState"]),
    ...mapState("remission", {
      remissionExistent: "remissionExistent",
    }),
    currentPath() {
      return this.$route.path;
    },
    titleAction() {
      return this.action == 2
        ? "remission.remission_registration"
        : this.action == 3
        ? "remission.remission_update"
        : this.action == 5 || this.action == 4
        ? "remission.remission_update"
        : "";
    },
    buttonTitle() {
      return this.action == 2
        ? "Registrar entrada"
        : this.action == 3
        ? "Modificar productos"
        : this.action == 4
        ? "Cambio de remisión"
        : this.action == 5
        ? "Modificar información"
        : "";
    },
  },
  methods: {
    ...mapActions({
      getRemissionExistent: "remission/getRemissionExistent",
      getListProducts: "remission/getListProducts",
    }),
    withPermissionName(permissions) {
      return UtilFront.withPermissionName(permissions);
    },
    ...mapMutations({
      updateAction: "auth/UPDATE_ACTION",
      listAllProducts: "remission/GET_PRODUCTS",
      setFiscalFound: "remission/SET_FISCAL_FOUND",
      listAllRequisitionGob: "remission/SET_REQUISITION",
      listAllSuppliers: "remission/SET_SUPPLIER",
    }),
    alert(type, message) {
      this.$refs.alertRemmision.reloadComponent(type, message);
    },
    showAction(action) {
      return this.action == action;
    },
    openModalUpdateProduct(item) {
      this.modelProduct = {};
      this.currentItem = item;
      this.$refs.RemissionModalUpdateProductComponent.setData(item);
      this.modelProduct.idProductRemmision = item.idProductRemmision;
      this.modelProduct.productId = item.products.idProduct;
      this.modelProduct.quantity = item.quantity;
      this.modelProduct.idFiscalFund = item.fiscalFund.idFiscalFund;
      this.modelProduct.unitPrice = item.unitPrice;
      this.modelProduct.lot = item.lot;
      this.modelProduct.expirationDate = moment(
        item.expirationDate,
        "DD/MM/YYYY"
      ).format("yyyy-MM-DD");
      this.modelProduct.idStock = item.idStock;
      this.dialogM.dialogUpdate = true;
    },
    async changeAction(action, item) {
      switch (action) {
        case 1:
          this.modelRemission.requisitionGobId = this.modelRemission.remissionkey = this.modelRemission.invoice = this.modelRemission.suppliers = this.modelRemission.idRemmision = this.modelRemission.remissionType = this.modelRemission.observations =
            "";
          this.productTableList = [];
          this.produdctsRemmision = [];
          this.modelChange.remmisionViewList = [];
          this.action = action;
          break;
        case 2:
          this.modelProduct = {};
          this.action = action;
          break;
        case 3:
        case 4:
        case 6:
          this.modelProduct = {};
          await this.getProductRemission(item, action);
          break;
        case 5:
          await this.getInfoRemission(item, action);
          break;
      }
    },
    async addProductUpdate(item, products) {
      const found = this.productTableList.find(
        (element) =>
          element.lot.trim().toUpperCase() == item.lot.trim().toUpperCase() &&
          item.productKey == element.products.productKey
      );
      if (found != undefined) {
        const { isConfirmed } = await SwalConfirm.fire({
          html:
            "Se ha encontrado un producto en la entrada con la misma información que intenta ingresar:" +
            " <br><br>Causes:<b> " +
            item.productKey +
            "</b> <br>Lote:<b> " +
            item.lot.trim().toUpperCase() +
            "</b>",
          icon: "warning",
          confirmButtonText:
            '<i class="feather icon-check mr-1 mr-1"></i> Si, continuar.',
        });
        if (!isConfirmed) {
          return;
        }
      }

      const { isConfirmed } = await SwalConfirm.fire({
        title:
          "El producto con la siguiente información sera agregado a la entrada. ¿Estás seguro? ",
        html:
          "<div class='row'>" +
          "<div class='col-md-12 pb-0'><b>" +
          "Causes: </b>" +
          item.productKey +
          "</div>" +
          "<div class='col-md-12 pb-0'><b>" +
          "Caducidad: " +
          "</b>" +
          item.expirationDate +
          "</div>" +
          "<div class='col-md-12  pb-0'><b>" +
          "Fuente: " +
          "</b>" +
          item.fiscalfund +
          "</div>" +
          "<div class='col-md-12  pb-0'><b>" +
          "Cantidad: " +
          "</b>" +
          item.quantity +
          "</div>" +
          "<div class='col-md-12'><b>" +
          "Lote: " +
          "</b>" +
          item.lot +
          "</div>" +
          "</div>",
      });
      if (!isConfirmed) {
        return;
      }

      this.loading = UtilFront.getLoadding();
      let params = {
        expirationDate: products.expirationDate,
        fiscalFund: {
          idFiscalFund: products.fiscalFund.idFiscalFund,
        },
        lot: item.lot,
        products: {
          idProduct: products.products.idProduct,
        },
        quantity: item.quantity,
        remissionType: 1,
        remmisionId: this.modelRemission.idRemmision,
        unitPrice: products.unitPrice,
        userBranchOffice: {
          idUser: this.user.idUser,
          idBranchOffice: this.branchOffice.idBranchoffice,
        },
      };
      RemissionRequest.addProductInRemmision(params)
        .then((response) => {
          let { message, success } = response.data.data;
          if (success) {
            this.loading.hide();
            this.alert("success", "Add_Product_0005");
            this.$refs.remissionAddProductFormComponent.clearAddProduct();
            this.reloadProductUpdate();
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.sending = false;
          //Cierro pantalla de carga
          this.loading.hide();
        });
    },
    loadRemmisionByBranchOffice() {
      this.loading = UtilFront.getLoadding();
      RemissionRequest.listAllChangeRemmisions({
        userBranchOfficeDTO: {
          idBranchOffice: this.branchOffice.idBranchoffice,
          idUser: UtilFront.getDataUser().idUser,
        },
      })
        .then((response) => {
          let { data, success, message } = response.data.data;
          if (success) {
            this.remmisionChangeList = data;
            this.dialogM.dialogRemmisionChange = true;
            this.remmisionChangeList = this.remmisionChangeList.filter(
              (data) => data.value !== this.modelRemission.idRemmision
            );
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          //Cierro pantalla de carga
          this.loading.hide();
        });
    },
    async reloadProductUpdate() {
      this.loading = UtilFront.getLoadding();
      let params = {
        remissionType: 1,
        accion:
          this.action == 3
            ? 1
            : this.action == 4
            ? 2
            : this.action == 6
            ? 3
            : 0,
        idRemmision: this.modelRemission.idRemmision,
        userBranchOfficeDTO: {
          idUser: UtilFront.getDataUser().idUser,
          idBranchOffice: this.branchOffice.idBranchoffice,
        },
      };
      await RemissionRequest.getProductRemission(params)
        .then((response) => {
          let { success, message, data } = response.data.data;
          if (success) {
            this.productTableList = data;
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    async getProductRemission(item, action) {
      this.loading = UtilFront.getLoadding();
      let params = {
        idRemmision: item.idRemmision,
        remissionType: 1,
        accion: action == 3 ? 1 : action == 4 ? 2 : action == 6 ? 3 : 0,
        userBranchOfficeDTO: {
          idUser: UtilFront.getDataUser().idUser,
          idBranchOffice: this.branchOffice.idBranchoffice,
        },
      };
      await RemissionRequest.getProductRemission(params)
        .then((response) => {
          let { success, message, data } = response.data.data;
          if (success) {
            this.modelRemission.idRemmision = item.idRemmision;
            this.modelRemission.remissionkeyOld = item.remissionKey;
            this.productTableList = data;
            this.action = action;
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    async getInfoRemission(item, action) {
      this.loading = UtilFront.getLoadding();
      let params = {
        idRemmision: item.idRemmision,
        userBranchOfficeDTO: {
          idUser: UtilFront.getDataUser().idUser,
          idBranchOffice: this.branchOffice.idBranchoffice,
        },
      };
      await RemissionRequest.getInfoRemission(params)
        .then((response) => {
          let { success, message, data } = response.data.data;
          if (success) {
            this.modelRemission = {
              idRemmision: item.idRemmision,
              suppliers: data.supplier.idSupplier,
              invoice: data.invoice,
              remissionkey: data.remissionkey,
              requisitionGobId: data.requisitionGobId,
              remissionType: data.remissionSubtype,
              remissionkeyOld: data.remissionkey,
              observations:
                data.observations == undefined ? "" : data.observations,
            };
            this.action = action;
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    async onClick() {
      switch (this.action) {
        case 2:
          var isValid = await this.$refs.remissionForm.validateForm();
          if (this.produdctsRemmision.length == 0) {
            this.alert("error", "Error_Product_Add_Remmision_0001");
          } else {
            if (isValid) {
              await this.getRemissionExistent(this.modelRemission.remissionkey);
              if (this.remissionExistent.length > 0) {
                let tableHTML = "";
                this.remissionExistent.map((res) => {
                  tableHTML =
                    tableHTML +
                    "<tr><td>" +
                    res.idRemmision +
                    "</td><td>" +
                    res.remissionkey +
                    "</td><td>" +
                    res.dateinput +
                    "</td></tr>";
                });
                const { isConfirmed } = await SwalConfirm.fire({
                  html:
                    this.remissionExistent.length > 1
                      ? "Se han encontrado las siguientes entradas con la misma referencia: <b>" +
                        this.modelRemission.remissionkey +
                        " </b><br>¿estás seguro de realizar esta acción? <br><br>" +
                        "<table class='table table-striped' responsive style='width:100%'><tr><th>ID Entrada</th><th>Referencia de Entrada</th> <th>Fecha de Registro</th></tr>" +
                        tableHTML +
                        "</table> "
                      : "Se encontro una entrada con la misma referencia: <b>" +
                        this.modelRemission.remissionkey +
                        " </b><br>¿estás seguro de realizar esta acción? <br><br>" +
                        "<table class='table table-striped' responsive style='width:100%'><tr><th>ID Entrada</th><th>Referencia de Entrada</th> <th>Fecha de Registro</th></tr>" +
                        tableHTML +
                        "</table> ",
                  icon: "warning",
                  confirmButtonText:
                    '<i class="feather icon-check mr-1 mr-1"></i> Si, continuar.',
                });
                if (!isConfirmed) {
                  return;
                }
                //Guardo la remision
                this.addRemission();
              } else {
                //Guardo la remision
                this.addRemission();
              }
            } else {
              this.alert("error", "Empty_Fields_0001");
            }
          }
          break;
        case 5:
          let isValidInfo = await this.$refs.remissionForm.validateForm();
          if (isValidInfo) {
            //Modificar la remision
            await this.getRemissionExistent(
              this.modelRemission.remissionkey.trim()
            );
            let remission = this.remissionExistent.filter(
              (element) =>
                element.idRemmision !== this.modelRemission.idRemmision
            );
            if (remission.length > 0) {
              let tableHTML = "";
              remission.map((res) => {
                tableHTML =
                  tableHTML +
                  "<tr><td>" +
                  res.idRemmision +
                  "</td><td>" +
                  res.remissionkey +
                  "</td><td>" +
                  res.dateinput +
                  "</td></tr>";
              });
              const { isConfirmed } = await SwalConfirm.fire({
                html:
                  remission.length > 1
                    ? "Se han encontrado las siguientes entradas con la misma referencia: <b>" +
                      this.modelRemission.remissionkey +
                      " </b><br>¿estás seguro de realizar esta acción? <br><br>" +
                      "<table class='table table-striped' responsive style='width:100%'><tr><th>ID Entrada</th><th>Referencia de Entrada</th> <th>Fecha de Registro</th></tr>" +
                      tableHTML +
                      "</table> "
                    : "Se encontro una entrada con la misma referencia: <b>" +
                      this.modelRemission.remissionkey +
                      " </b><br>¿estás seguro de realizar esta acción? <br><br>" +
                      "<table class='table table-striped' responsive style='width:100%'><tr><th>ID Entrada</th><th>Referencia de Entrada</th> <th>Fecha de Registro</th></tr>" +
                      tableHTML +
                      "</table> ",
                icon: "warning",
                confirmButtonText:
                  '<i class="feather icon-check mr-1 mr-1"></i> Si, continuar.',
              });
              if (!isConfirmed) {
                return;
              }
              this.updateRemmision();
            } else {
              this.updateRemmision();
            }
          } else {
            this.alert("error", "Empty_Fields_0001");
          }
          break;
        default:
          break;
      }
    },
    async updateRemmision() {
      const { isConfirmed } = await SwalConfirm.fire({
        text: "La información de la entrada sera modificada.",
      });
      if (!isConfirmed) {
        return;
      }
      this.loading = UtilFront.getLoadding();
      let data = {
        idRemmision: this.modelRemission.idRemmision,
        remissionkey: this.modelRemission.remissionkey,
        invoice: this.modelRemission.invoice,
        observations: this.modelRemission.observations,
        remissionSubtype: this.modelRemission.remissionType,
        requisitionGobId: this.modelRemission.requisitionGobId,
        supplier: {
          idSupplier:
            this.modelRemission.suppliers === undefined
              ? ""
              : this.modelRemission.suppliers,
        },
        userBranchOfficeDTO: {
          idUser: this.user.idUser,
          idBranchOffice: this.branchOffice.idBranchoffice,
        },
      };
      RemissionRequest.updateRemmision(data)
        .then(async (response) => {
          let { message, success } = response.data.data;
          if (success) {
            this.loading.hide();
            this.alert("success", "Update_Success_0001");
            this.changeAction(1);
            await this.listAllRemmisions(this.pagesAtributes);
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    async addRemission() {
      const { isConfirmed } = await SwalConfirm.fire({
        html:
          "La entrada sera registrada en </br><b>" +
          UtilFront.getByIdBranchName(this.branchOffice.idBranchoffice)[0]
            .text +
          "</b>",
      });
      if (!isConfirmed) {
        return;
      }
      this.loading = UtilFront.getLoadding();
      let params = {
        remissionSubtype: this.modelRemission.remissionType,
        remissionkey: this.modelRemission.remissionkey,
        requisitionGobId: this.modelRemission.requisitionGobId,
        produdctsRemmision: this.produdctsRemmision,
        supplier: {
          idSupplier: this.modelRemission.suppliers,
        },
        userBranchOfficeDTO: {
          idBranchOffice: this.branchOffice.idBranchoffice,
          idUser: UtilFront.getDataUser().idUser,
        },
        invoice: this.modelRemission.invoice || "ND",
        observations: this.modelRemission.observations,
      };
      RemissionRequest.addRemission(params)
        .then(async (response) => {
          let { success, message } = response.data.data;
          if (success) {
            this.loading.hide();
            this.alert("success", "Add_Success_0001");
            this.changeAction(1);
            this.productTableList = [];
            this.produdctsRemmision = [];
            await this.listAllRemmisions(this.pagesAtributes);
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    addProductListComponent(productTableList, produdctsRemmision) {
      this.productTableList.push(productTableList);
      this.produdctsRemmision.push(produdctsRemmision);
      this.$refs.remissionAddProductFormComponent.clearAddProduct();
    },
    async deleteRecordRemmi(item) {
      let currentIndex = this.productTableList.findIndex(
        (element) => element.id == item.item.id
      );
      this.productTableList.splice(currentIndex, 1);
      this.produdctsRemmision.splice(currentIndex, 1);
    },
    async listAllRemmisions(page) {
      this.loading = UtilFront.getLoadding();
      await RemissionRequest.listAllRemmisionByBranch({
        type: this.remissionType,
        idUser: UtilFront.getDataUser().idUser,
        idBranchOffice: this.branchOffice.idBranchoffice,
        page,
      })
        .then((response) => {
          let { data } = response;
          this.listRemission = data;
        })
        .catch((error) => {
          console.log(error);
          this.alert("error", error.message);
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    detail(item) {
      this.loading = UtilFront.getLoadding();
      RemissionRequest.remmisionGetWithProductsRemmision({
        idRemmision: item.idRemmision,
        remissionType: 1,
        userBranchOfficeDTO: {
          idUser: UtilFront.getDataUser().idUser,
          idBranchOffice: this.branchOffice.idBranchoffice,
        },
      })
        .then((res) => {
          let { data, success, message } = res.data.data;
          if (success) {
            this.model = data;
            this.model.suppliersName = this.model.supplier.name;
            this.model.totalProducts = this.model.produdctsRemmision
              .map((obj) => {
                return obj.quantity;
              })
              .reduce((a, b) => {
                return a + b;
              }, 0);
            this.dialogM.dialog = true;
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    downloadPDF(item) {
      let send =
        "idRemision=" +
        item.idRemmision +
        "&" +
        "idSucursal=" +
        this.branchOffice.idBranchoffice;
      ExcelRequest.reportRemmisionPdf(send);
      this.$toast.success("Documento generado (documento.pdf)");
    },
    excelTable() {
      this.$toast.success("Reporte generado (documento.xlsx)");
      ExcelRequest.reportRemmisions({
        remissionType: this.remissionType,
        userBranchOfficeDTO: {
          idBranchOffice: this.branchOffice.idBranchoffice,
          idUser: UtilFront.getDataUser().idUser,
        },
      });
    },
  },
  async created() {
    this.user = UtilFront.getDataUser();
    this.dialogM.dialog = false;
    this.headers = this.remmisionHeaders;
    this.title = "remission.remission_supplier";
    this.remissionType = 1;
    // Actualiza la sucursal para el componente
    this.updateAction(1);
    /* Lista GET_PRODUCTS: Produtos */
    await this.getListProducts();
    /* List SET_REQUISITION: Pedidos */
    this.listAllRequisitionGob(this.branchOffice.idBranchoffice);
    /* List SET_SUPPLIER: Provedor */
    this.listAllSuppliers();
  },
  watch: {
    // En caso de que cambie la sucursal actualiza la tabla
    async branchOffice() {
      this.showTable = false;
      this.pagesAtributes = {
        currentPage: 0,
        itemsPerPage: 15,
        search: "",
        sortBy: "",
        sortFilters: {}
      };
      await this.listAllRemmisions(this.pagesAtributes);
      this.$nextTick(() => {
        this.showTable = true;
      });
    },
    action() {
      this.updateAction(this.action);
    },
    "$i18n.locale": function() {
      this.productsHeader = CREATE_REMMISION();
      this.headers = REMMISION();
      this.remmisionViewHeaders = REMMISION_VIEW();
    },
  },
  components: {
    AlertMessageComponent,
    RemissionTableComponent,
    SelectBranchOffice,
    ButtonPrintComponent,
    RemissionsModalDetail,
    RemissionAddProductFormComponent,
    RemissionFormComponent,
    RemissionTableProductComponent,
    ButtonAction,
    RemissionModalUpdateProductComponent,
    RemissionModalChangeProductComponent,
  },
};
</script>
